import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import { useReducedMotion } from './utils/ReducedMotion';

export interface VideoT extends BoxT {
  src: string;
  posterSrc?: string | undefined;
  play?: boolean | undefined;
  loop?: boolean | undefined;
  disableRemotePlayback?: boolean | undefined;
}

export const Video = ({
  src,
  posterSrc,
  loop = false,
  play = false,
  disableRemotePlayback = false,
  ...rest
}: VideoT) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const reducedMotion: boolean = useReducedMotion();
  const [hasPlayedOnce, setHasPlayedOnce] = useState(false);

  const startAnimation = useCallback(async () => {
    try {
      const { current: video } = videoRef;
      //never re-play a video
      if (!video || hasPlayedOnce) return;
      await video.play();
    } catch (e) {
      //eslint-disable-next-line no-console
      console.log(e);
    }
  }, [hasPlayedOnce]);

  const animationEnded = useCallback(() => {
    setHasPlayedOnce(true);
  }, []);

  useEffect(() => {
    const { current: video } = videoRef;
    if (!video) return;
    video.addEventListener('ended', animationEnded);
    return () => video.removeEventListener('ended', animationEnded);
  }, [animationEnded]);

  useEffect(() => {
    if (reducedMotion) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    play && startAnimation();
  }, [play, startAnimation, reducedMotion]);

  const setEndFrame = () => {
    const video = videoRef.current;
    if (!video) return;
    const { duration } = video;
    video.currentTime = duration;
  };

  // Accessibility: Show last frame if reduced motion is preferred
  useEffect(() => {
    if (!reducedMotion) return;
    const video = videoRef.current;
    if (!video) return;
    video.addEventListener('loadedmetadata', setEndFrame);
    return () => video.removeEventListener('loadedmetadata', setEndFrame);
  }, [reducedMotion]);

  return (
    <Box {...rest}>
      <video
        ref={videoRef}
        loop={loop}
        disableRemotePlayback={disableRemotePlayback}
        playsInline
        muted
        src={src}
        poster={posterSrc}
        width="100%"
        height="100%"
      />
    </Box>
  );
};
