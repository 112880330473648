import * as React from 'react';
import type { Responsive } from '@mentimeter/ragnar-styled';
import type { Width } from '@mentimeter/ragnar-dsc';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { FrameT } from '../FrameBrowser';
import { Ratio, type RatioValue } from '../Ratio';
import { Box, type BoxT } from '../Box';

export interface FramePhoneT extends FrameT, Omit<BoxT, 'children'> {
  children: React.ReactNode;
  width?: Responsive<Width>;
}

export const FramePhone = ({
  children,
  customRatio = '216.6667%', //19:5:9 iPhone X,
  ratio,
  width,
  className,
  ...rest
}: FramePhoneT & RatioValue) => {
  return (
    <Box
      className={clsx(['relative', 'w-[340px]'], className)}
      style={
        {
          ...(width && { width }),
        } as React.CSSProperties
      }
      {...rest}
    >
      <Box
        className={clsx([
          'w-[inherit]',
          'h-full',
          'overflow-hidden',
          'border-[6px]',
          'border-solid',
          'border-weaker',
          'bg-primary-weakest',
          'rounded-[35px]',
        ])}
      >
        {ratio ? (
          <Ratio ratio={ratio} className={clsx(['w-full', 'bg'])}>
            {children}
          </Ratio>
        ) : (
          <Ratio customRatio={customRatio} className={clsx(['w-full', 'bg'])}>
            {children}
          </Ratio>
        )}
      </Box>
    </Box>
  );
};
