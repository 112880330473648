'use client';
import type { Document } from '@contentful/rich-text-types';
import { Box, FrameBrowser, FramePhone } from '@mentimeter/ragnar-ui';
import type { ImageDecoration } from 'shared/cms/content-type-types';
import { CaptionRenderer } from 'src/cms/CaptionRenderer';
import type { NextImageProps } from 'src/components/image/NextImage';
import NextImage from 'src/components/image/NextImage';

export interface DecoratedNextImageT extends NextImageProps {
  decoration?: ImageDecoration | undefined;
  caption?: Document | undefined;
}

export const DecoratedNextImage = ({
  decoration,
  caption,
  ...rest
}: DecoratedNextImageT) => {
  const { width = 600, height = 400 } = rest;

  switch (decoration) {
    case 'browser':
      return (
        <>
          <FrameBrowser>
            <NextImage {...rest} width={width} height={height} />
          </FrameBrowser>
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    case 'phone':
      return (
        <>
          <FramePhone>
            <NextImage
              style={{
                flexShrink: 0,
              }}
              {...rest}
              width={width}
              height={height}
            />
          </FramePhone>
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    case 'round':
      return (
        <>
          <Box borderRadius={3} overflow="hidden" bg="neutralWeakest">
            <NextImage
              style={{
                flexShrink: 0,
              }}
              {...rest}
              width={width}
              height={height}
            />
          </Box>
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    default:
      return (
        <>
          <NextImage
            style={{
              flexShrink: 0,
            }}
            {...rest}
            width={width}
            height={height}
          />
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
  }
};
