import * as React from 'react';

export interface UseReducedMotionT {
  reducedMotion: boolean;
}

export const useReducedMotion = () => {
  const [reducedMotion, setReducedMotion] = React.useState<boolean>(true);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion)');
    const handleChange = () => {
      if (mediaQuery.media === 'not all') {
        //for browsers that don't support prefers-reduced-motion, show no animations
        setReducedMotion(true);
      } else {
        setReducedMotion(mediaQuery.matches);
      }
    };
    handleChange();
    if (mediaQuery.addEventListener === undefined) {
      mediaQuery.addListener(handleChange);
    } else {
      mediaQuery.addEventListener('change', handleChange);
    }

    return () => {
      if (mediaQuery.removeEventListener === undefined) {
        mediaQuery.removeListener(handleChange);
      } else {
        mediaQuery.removeEventListener('change', handleChange);
      }
    };
  }, []);

  return reducedMotion;
};
