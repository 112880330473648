import * as React from 'react';

export interface State {
  inView: boolean;
  scrollPosition: number;
}

interface InViewT {
  threshold?: number; // between 0.0-1.0 which will represent the height of the child
  children: (state: State) => React.ReactNode;
}

export const InView = ({ threshold = 0.0, children }: InViewT) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [inView, setInview] = React.useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = React.useState<number>(0);

  const calculate = React.useCallback(() => {
    if (ref.current === null) return;
    const prevInView = inView;
    const { current: element } = ref;
    const rect = element.getBoundingClientRect();
    const { innerHeight } = window;
    const { top, bottom, height } = rect;
    const inViewNow =
      top <= innerHeight - height * threshold && bottom >= height * threshold;
    // Do not update unnecessarily
    if (prevInView !== inViewNow) setInview(inViewNow);
    // Do not calculate position if not in view
    if (!inView) return;
    const positionOfRect = top;
    const windowSize = innerHeight;
    const scrollPercent = Math.round(100 * (positionOfRect / windowSize)) / 100;
    setScrollPosition(scrollPercent);
  }, [threshold, inView]);

  React.useEffect(() => {
    calculate();
    window.addEventListener('scroll', calculate);
    window.addEventListener('resize', calculate);
    return () => {
      window.removeEventListener('scroll', calculate);
      window.removeEventListener('resize', calculate);
    };
  }, [calculate]);

  return (
    <div ref={ref} style={{ width: '100%' }}>
      {children({ inView, scrollPosition })}
    </div>
  );
};
